// import axios from '../utils/ajax';
import * as axios from "./request.js";
/**
 *
 * @param {*} params {}
 * baseUrl  接口路径
 */
var baseTjUrl;
export var baseToUrl;
if (process.env.NODE_ENV == "development") {
  // 开发环境 本地 npm run dev
  baseTjUrl = "https://fuyinkehuduan.com:8089";
  baseToUrl = "http://192.168.1.195:8080/#/";
  // baseTjUrl = "http://192.168.1.169:8089";
} else if (process.env.NODE_ENV == "production") {
  // 线上环境 服务器 npm run build
  baseTjUrl = "https://fuyinkehuduan.com:8089";
  baseToUrl = "http://47.108.148.219/#/";
}

//体检
export const getTjQrcode = async params =>
  axios.post(baseTjUrl + "/wechat/code", params); //查看体检二维码
export const getAreaData = async params =>
  axios.get(baseTjUrl + "/wechat/getArea", params); //获取省市区
export const getTjNames = async params =>
  axios.get(baseTjUrl + "/wechat/findName", params); //获取社区学校名称
export const getTjListData = async params =>
  axios.get(baseTjUrl + "/wechat/findMessage", params); //生成码列表查询接口
export const saveTjNum = async params =>
  axios.get(baseTjUrl + "/wechat/saveNum", params); //保存总人数、检出人数
export const getTjQrcodeCommunity = async params =>
  axios.post(baseTjUrl + "/wechat/saveCommunity", params); //保存社区信息时的接口
export const getTjQrcodeWxCode = async params =>
  axios.post(baseTjUrl + "/wechat/getWxCode", params); //（查体人输入学校绑定时的接口）参数：id