/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from 'axios';

// 请求超时时间
axios.defaults.timeout = 240000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'origin, x-csrftoken, content-type, accept, x-auth-code, X-App-Id, X-Token';
axios.defaults.headers.post['Access-Control-Allow-Method'] = 'POST, GET, OPTIONS';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// 请求拦截器
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断

        // const token = sessionStorage.getItem('fy_token');
        // token && (config.headers.fy_token = token);
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
axios.interceptors.response.use(

    response => {
        // console.log(response)
       return Promise.resolve({ data: response });
    },
    // 服务器状态码不是200的情况
    error => {
        // console.log(error)
        // if (error.response.data.returnCode) {
            // return Promise.reject(error);
        // }
    }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);

            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
